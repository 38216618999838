<template>
   <div>
      <div class="el-textarea">
        <el-button @click="openConnect">开始连接</el-button>
        <el-button @click="readerCard">去读卡</el-button>
        <el-button @click="breakConnect">断开连接</el-button>
      </div>
   </div>
</template>
<script>
import CardReader from '@/utils/cardReader/CardReader'
export default {
   data() {
      return {
         content: "",
      };
   },
  mounted() {

  },
  methods: {
    openConnect() {
      const cardReader = new CardReader()
      // 开始连接
      cardReader.connect()
    },
    readerCard() {
      const cardReader = new CardReader()
      // 去读卡
      const result = cardReader.readCert()
      console.log(result)
    },
    breakConnect() {
      const cardReader = new CardReader()
      // 断开连接
      cardReader.disconnect()
    }
  },
};
</script>
<style lang="scss">
.el-textarea {
   width: 100%;
   textarea {
      width: 100px;
      overflow: auto;
      word-break: break-all; //解决兼容问题
   }
}
</style>
